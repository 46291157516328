import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import styled from 'styled-components';


const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#ccc';
};
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-width: 3px;
  border-radius: 10px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #f4f4f4;
  min-height: 250px;
  color: black;
  font-weight: bold;
  font-size: 1.4rem;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
function DropBox({ onDropEvent }) {

  const [file, setFile] = useState();

  const onDrop = (props) => {
    setFile(URL.createObjectURL(props[0]));
    onDropEvent(props[0]);
  };

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  let content;

  if (acceptedFiles.length === 0) {
    content = <>
      <p>Drag 'n' drop some image here</p>
      <button type="button" className="btn" onClick={open}>
        Click to select file
      </button>
    </>;
  }
  else {
    content = <><img style={{width:'100%', borderRadius: "5px"}} src={file}  alt="Preview"/></>;
  }

  return (
        <Container
          className="dropbox"
          {...getRootProps({ isDragAccept, isFocused, isDragReject })}
        >
          <input {...getInputProps()} />
          {content}
        </Container>
  );
}
export default DropBox;
