import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography } from '@mui/material';
import DropBox from "../components/img-upload";
import API from "../services/api";


const partial = (func, ...args) => (...rest) => func(...args, ...rest);


export default function FaceCompare() {

  const [imageData, setData] = useState({
    left: undefined, right: undefined
  });

  const [scoreInfo, setscoreInfo] = useState(<></>);
  const [bgColor, setBgColor] = useState("#000");
  const [bgOpacity, setBgOpacity] = useState(0);

  const onDrop = async (position, image) => {
    let tmp = imageData;
    tmp[position] = image;
    setData(tmp);
    setBgColor("#A3A36C");
    setscoreInfo(<></>);

    if (imageData.left !== undefined && imageData.right !== undefined) {
      try {
        let result = await API.faceCompare(imageData.left, imageData.right);
        let score = result.result.score;
        console.log(result);
        if (score > 0.7) {
          setscoreInfo(
            <>
              <Typography variant="h4" style={{color: "#fff"}}>Match</Typography>
              <Typography style={{fontSize: "12px", color: "#212B36"}} sx={{ fontWeight: 'bold' }}>
                {score.toFixed(3)}
              </Typography>
            </>
          );
          setBgColor("#00A36C");
        }
        else {
          setscoreInfo(
            <>
              <Typography variant="h4" style={{color: "#fff"}}>Other</Typography>
              <Typography style={{fontSize: "12px", color: "#212B36"}} sx={{ fontWeight: 'bold' }}>
                {score.toFixed(3)}
              </Typography>
            </>
          );
          setBgColor("#D22B2B");
        }
      }
      catch (e) {
        setscoreInfo(
          <Typography variant="h4" style={{color: "#F00"}} gutterBottom>
            Error: {e}
          </Typography>
        );
      }
      setBgOpacity(1);
    }

  };

  return (
    <>
      <Helmet>
        <title> Face Compare Demo | CloudFace Admin </title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Face Compare Demo
          </Typography>
        </Stack>
        <Stack style={{position: "relative"}} direction="row" alignItems="flex-start" spacing={2} justifyContent="space-between" mb={2}>
          <DropBox onDropEvent={partial(onDrop, "left")}/>
          <DropBox onDropEvent={partial(onDrop, "right")}/>
          <Container style={{
            backgroundColor: bgColor,
            position: "absolute",
            left: "50%",
            width: "120px",
            height: "120px",
            marginLeft: "-60px",
            marginTop: "-60px",
            borderRadius: "100%",
            display: "flex",
            padding: "0",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            opacity: bgOpacity,
          }}>
            {scoreInfo}
          </Container>
        </Stack>
      </Container>
    </>
  );
}
