import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Stack, Typography } from '@mui/material';
import ReactJson from 'react-json-view';
import DropBox from "../components/img-upload";
import API from "../services/api";


export default function FaceInfo() {

  const [jsonData, setData] = useState({});

  const onDrop = async (image) => {
    setData({})
    try {
      let result = await API.faceInfo(image);
      setData(result);
    }
    catch (e) {
      setData({"error": e});
    }
  };

  return (
    <>
      <Helmet>
        <title> Face Info Demo | CloudFace Admin </title>
      </Helmet>

      <Container maxWidth={false}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Face Info Demo
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="flex-start" spacing={2} justifyContent="space-between" mb={2}>
          <DropBox onDropEvent={onDrop}/>
          <ReactJson style={{width: "500px"}} src={jsonData} />
        </Stack>
      </Container>
    </>
  );
}
