import * as React from "react";

import Cookies from "js-cookie";

import {
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import API from './api';


export const AuthContext = React.createContext(null);


const doAuth = async (login, password) => {
  return await API.login(login, password)
};


export const doLogout = () => {
  Cookies.remove("auth-token", { path: '' });
  Cookies.remove("api-token", { path: '' });
  localStorage.removeItem("email");
  localStorage.removeItem("name");
  localStorage.removeItem("id");
}


export const getAuthToken = () => {
  let token = Cookies.get("auth-token");
  return token === undefined ? "" : token;
};


export const getAPIToken = () => {
  let token = Cookies.get("api-token");
  return token === undefined ? "" : token;
};


export const AuthProvider = ({ children }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [token, setToken] = React.useState(null);

  const handleLogin = async (login, password, setError) => {
    try {

      // Do authorize
      const result = await doAuth(login, password);
      const token = result['access_token'];
      setToken(token);
      Cookies.set("auth-token", token, { path: '' });
      localStorage.setItem("email", result['user_info']['email']);
      localStorage.setItem("name", result['user_info']['name']);

      // Get token
      // const tokens = await API.getMyTokens();
      // if (tokens.length === 0) {
      //   const token_info = await API.createToken(result['user_info']['id'], 0);
      //   Cookies.set("api-token", token_info['token'], { path: '' });
      // }
      // else {
      //   const token_info = tokens[0];
      //   Cookies.set("api-token", token_info['token'], { path: '' });
      // }

      const origin = location.state?.from?.pathname || '/admin';
      navigate(origin);
    }
    catch (error) {
      setError(error.message);
    }
  };

  const value = {
    token,
    doLogin: handleLogin,
    // onLogout: handleLogout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );

};


export const ProtectedRoute = ({ children }) => {
  const token = getAuthToken();
  const location = useLocation();

  if (!token) {
    return <Navigate to="/admin/login" replace state={{ from: location }} />;
  }

  return children;
};
