import Cookies from "js-cookie";


const getAuthToken = () => {
  let token = Cookies.get("auth-token");
  return token === undefined ? "" : token;
};


// const getAPIToken = () => {
//   let token = Cookies.get("api-token");
//   return token === undefined ? "" : token;
// };


class _API {

  path = "https://cloudface.panel.limavi.net"
  // path = "http://localhost:8088"

  async #call(path, data, token) {
    let response;
    const url = this.path + path;
    const headers = Object.assign({}, {
        "Authorization": "Bearer " + token
      }, data.headers === undefined ? {} : data.headers);

    const request = Object.assign({}, {
      mode: "cors",
      cache: "no-cache"
    }, data);

    request.headers = headers;

    try {
      response = await fetch(url, request);
    }
    catch (error) {
      throw new Error(error);
    }

    let result = await response.json();
    if (response.status >= 200 && response.status < 300) {
      return result;
    }
    else {
      throw new Error(result.detail);
    }

  }

  async login(login, password) {
    const data = {
      "email": login,
      "password": password
    };
    return await this.#call("/v1/auth/login", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {"Content-Type": "application/json"}
    }, "");
  }

  async faceInfo(face) {
    const formData  = new FormData();
    formData.append("image", face);
    return await this.#call("/v1/faces/detect?demography=1&emotions=1&quality=1&liveness=1&max_size=4096", {
      method: "POST",
      body: formData,
    }, getAuthToken());
  }

  async faceSearch(face) {
    const formData  = new FormData();
    formData.append("image", face);
    return await this.#call("/v1/faces/find", {
      method: "POST",
      body: formData,
    }, getAuthToken());
  }

  async faceCompare(face1, face2) {
    const formData  = new FormData();
    formData.append("image1", face1);
    formData.append("image2", face2);
    return await this.#call("/v1/faces/compare?max_size=4096", {
      method: "POST",
      body: formData,
    }, getAuthToken());
  }

  async getFaces(page, limit) {
    return await this.#call("/v1/faces/?limit=" + limit + "&page=" + page, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    }, getAuthToken());
  }

  async getMyTokens() {
    return await this.#call("/v1/tokens/", {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    }, getAuthToken());
  }

  async createToken(user_id, expire) {
    return await this.#call("/v1/users/" + user_id + "/tokens", {
      method: "POST",
      body: JSON.stringify({
        "expire_in_minutes": expire,
      }),
      headers: {"Content-Type": "application/json"},
    }, getAuthToken());
  }

}


const API = new _API()
export default API;
