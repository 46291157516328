// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/admin/home',
    icon: icon('ic_analytics'),
  },
  {
    title: 'users',
    path: '/admin/users',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  {
    title: 'Face Info',
    path: '/admin/face-info',
    icon: icon('ic_blog'),
  },
  {
    title: 'Face Compare',
    path: '/admin/face-compare',
    icon: icon('ic_blog'),
  },
  {
    title: 'Face Search',
    path: '/admin/face-search',
    icon: icon('ic_blog'),
  },
];

export default navConfig;
