import { useState, useContext, useRef } from 'react';
// @mui
import { Stack, IconButton, InputAdornment, TextField, Container, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import {AuthContext} from "../../../services/auth";


export default function LoginForm() {
  const auth = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState(" ");

  const loginRef = useRef(null);
  const passwordRef = useRef(null);

  const handleClick = async (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    let login = loginRef.current.value;
    let password = passwordRef.current.value;
    await auth.doLogin(login, password, setError);
  };

  return (
    <form onSubmit={handleClick}>
      <Stack spacing={3}>
        <TextField autoComplete="email" name="email" inputRef={loginRef} label="Email address" />

        <TextField
          autoComplete="password"
          name="password"
          label="Password"
          inputRef={passwordRef}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained">
        Login
      </LoadingButton>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Container>
            <Box sx={{ color: 'error.main' }} textAlign="center">{errorMessage}</Box>
          </Container>
        </Stack>

    </form>
  );
}
