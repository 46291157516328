import * as React from "react";

import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute, AuthProvider } from './services/auth'

// layouts
import DashboardLayout from './layouts/dashboard';
import LoginPage from './pages/LoginPage';
import DashboardAppPage from './pages/DashboardAppPage';
import FaceInfo from './pages/FaceInfo';
import FaceCompare from "./pages/FaceCompare";
import FaceSearch from "./pages/FaceSearch";
import Page404 from './pages/Page404';
import ProductsPage from "./pages/ProductsPage";


export default function Router() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/admin" element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
          <Route index element={ <Navigate to="/admin/home" /> } />
          <Route path="home" element={<DashboardAppPage />} />
          <Route path="users" element={<ProtectedRoute><ProductsPage /></ProtectedRoute>} />
          <Route path="face-info" element={<ProtectedRoute><FaceInfo /></ProtectedRoute>} />
          <Route path="face-compare" element={<ProtectedRoute><FaceCompare /></ProtectedRoute>} />
          <Route path="face-search" element={<ProtectedRoute><FaceSearch /></ProtectedRoute>} />
        </Route>
        <Route path="/admin/login" element={<LoginPage />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </AuthProvider>
  );
}
