import { Helmet } from 'react-helmet-async';
import { useState, useEffect, useMemo } from 'react';
// @mui
import {Container, Stack, Typography} from '@mui/material';
// components
import { ProductList } from '../sections/@dashboard/products';
import API from "../services/api";
import {Pagination} from "@mui/lab";
import { useLocation, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const PER_PAGE = 32;


function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}


export default function ProductsPage() {
  const query = useQuery();
  const [faces, setFaces] = useState([]);
  const [page_id, setPageID] = useState(parseInt(query.get("page") ?? "1"));
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState(1);
  const navigate = useNavigate();

  const handleChange = (event, value) => {
    setPageID(value);
    navigate('?page=' + value);
  };

  useEffect(() => {
    setFaces([]);
    window.scrollTo(0, 0);
    (async () => {
      let result = await API.getFaces(page_id, PER_PAGE);
      setCount(Math.ceil(result.count / PER_PAGE));
      setTotal(result.count);
      setFaces(result.faces);
    })();
  }, [page_id]);

  return (
    <>
      <Helmet>
        <title> Faces | CloudFace Admin </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {total} faces
        </Typography>

        <ProductList products={faces} />

        <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 8 }}>
          <Pagination count={count} page={page_id} onChange={handleChange} />
        </Stack>
      </Container>
    </>
  );
}
